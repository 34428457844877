<template>
  <div class="home_top">
    <div class="inner inner1">
      <div class="left_item">
        <h1>¡Bienvenido a DineroRápido!</h1>
        <h1>La app líder en préstamos </h1>
        <h1>rápidos y fáciles en México.</h1>
        <h4>Ya sea que necesites dinero para una emergencia, pagar una</h4>
        <h4>factura inesperada o simplemente hacer realidad tus sueños,</h4>
        <h4>nosotros estamos aquí para ayudarte.</h4>
        <div class="item_download" @click="downloadApp()">
          <img src="@/assets/images/Google.png" alt="">
        </div>
      </div>
      <div class="right_item">
        <!-- <img src="@/assets/images/banner01.png" alt=""> -->
      </div>
    </div>
    <div id="selector1" class="inner inner2">
      <!-- <img class="left_bg" src="@/assets/images/inner2_left.png"> -->
      <h3>¿Cómo funciona DineroRápido? </h3>
      <!-- <p>
        Hollandale Nigeria Limited is an international non-bank financial institution
        founded in 2020. In 2022 company expanded to Nigeria&Ghana. The company operates
        in 2 countries and focuses on lending Primarily to people with little or no credit
        history. As of 2023 the company has served already over 7 million active
        customers.
      </p> -->
      <div class="inner5_item">
        <img src="@/assets/images/loanImg/inner3.png">
      </div>
    </div>
    <div id="selector2" class="inner inner3">
      <div class="title">
        <h3>¿Por qué elegir DineroRápido?</h3>
      </div>
      <ul class="list">
        <li class="left_item">
          <p class="text1">Préstamos rápidos</p>
          <p class="text2">
            Obtén el dinero que necesitas en minutos. Nuestro proceso<br>
            de solicitud es fácil y rápido, sin largas esperas ni trámites<br>
            complicados.
          </p>
        </li>
        <li class="right_item">
          <p class="text1">Solicitar préstamos</p>
          <p class="text2">
            Puede elegir el monto de préstamos de acuerdo con sus<br>
            necesidades y agregar una cuenta para recibir el dinero.<br>
            Puede solicitar préstamos y ver los resultados de las<br>
            verificaciones.
          </p>
        </li>
        <li class="left_item last">
          <p class="text1">Sin aval ni garantía</p>
          <p class="text2">
            No te preocupes por encontrar un aval o presentar<br>
            garantías. En DineroRápido, nos enfocamos en brindar<br>
            préstamos sin complicaciones y sin requerir garantías adicionales.
          </p>
        </li>
        <li class="right_item last">
          <p class="text1">Sin papeleos</p>
          <p class="text2">
            Olvídate de los formularios y documentos<br>
            interminables. Con DineroRápido, todo<br>
            se hace en línea, desde la comodidad de tu<br>
            hogar o en cualquier lugar con acceso a internet.
          </p>
        </li>
        <li class="left_item last">
          <p class="text1">Atención al cliente excepcional</p>
          <p class="text2">
            Nuestro equipo de servicio al cliente está disponible para<br>
            responder a tus preguntas y brindarte cualquier ayuda que<br>
            necesites. Estamos comprometidos en ofrecerte la mejor<br>
            experiencia posible.
          </p>
        </li>
        <li class="right_item last">
          <p class="text1">Totalmente seguro</p>
          <p class="text2">
            Tus datos personales y financieros estar án protegidos<br>
            en todo momento. Utilizamos tecnología de seguridad<br>
            avanzada para garantizar la confidencialidad y privacidad<br>
            de tu información.
          </p>
        </li>
      </ul>
      <div class="phone">
        <img src="@/assets/images/loanImg/phone.png" alt="">
      </div>
    </div>
    <div class="inner inner4">
      <!-- <h4>Sobre nosotros</h4> -->
      <div class="content">
        <div class="left_cell">
          <!-- <h1>Mission&Vision</h1> -->
          <p>En DineroRápido, nos preocupamos<br>
            por tu seguridad y privacidad. </p>
          <p>
            Utilizamos tecnologías avanzadas de encriptación<br>
            para proteger tus datos y garantizar que toda la<br>
            información personal que compartes con<br>
            nosotros esté protegida.
          </p>
        </div>
        <div class="right_cell">
          <img src="@/assets/images/loanImg/about_inner2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemList: [
        {
          title: 1,
          txt: 'Download the app Search “EasyCash” from the Google Play Store and download it.',
          img: require('@/assets/images/content01.png')
        },
        {
          title: 2,
          txt: 'Register Sign up with your phone number, finish some infomation in 2 minutes',
          img: require('@/assets/images/content02.png')
        },
        {
          title: 3,
          txt: 'Apply for a loan Click the “Submit” button to apply for a loan.After that, the money will be received to your bank account.',
          img: require('@/assets/images/content03.png')
        }
      ],
      leftItemList: [
        { txt: 'Altruism', img: require('@/assets/images/Product01.png') },
        { txt: 'Tenacity', img: require('@/assets/images/Product02.png') },
        {
          txt: 'Innovate',
          txt2: 'mortgage',
          img: require('@/assets/images/Product03.png')
        },
        { txt: 'Integrity', img: require('@/assets/images/Product04.png') },
        { txt: 'Respect', img: require('@/assets/images/Product05.png') }
      ],
      leftItemList2: [
        { title: 'Download  app', txt: 'Sign Up ' },
        { title: 'Register an account', txt: 'Fill In Info' },
        { title: 'Fill out online forms', txt: 'Waiting For Review' },
        { title: 'Submit the application', txt: 'Get Loan' },
        { title: 'Submit the application', txt: 'Select Amount ', txt2: 'And Duration' },
        { title: 'Submit the application', txt: 'Repay' }
      ],
      inner6ItemLeftList: [
        {
          title: 'Danna Roberto',
          txt: 'Famous loan application app,the most',
          txt2: 'convenient way to get a loan for those who ',
          txt3: 'need o borrow money',
          img: require('@/assets/images/ng_1.png')
        },
        {
          title: 'Miller Abigail',
          txt: 'Very good app to help you use whenyou are ',
          txt2: 'in trouble, the app is easy tounderstand, ',
          txt3: 'everyone can submit',
          img: require('@/assets/images/ng_2.png')
        },
        {
          title: 'Davis Aron',
          txt: 'Very little information required, verysimple, ',
          txt2: 'hope to receive money soon,easy to apply, ',
          txt3: '5 stars',
          img: require('@/assets/images/ng_3.png')
        }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=dinero.rapido.seguro', '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top {
  min-width: 1200px;
  background: #fff;
  .inner {
    width: 100%;
    padding: 50px calc((100% - 1300px) / 2);
    margin: 0 auto;
  }
  .inner1 {
    // background: linear-gradient(258deg, #43C5D6 0%, #4484EC 100%);
    background: url("~@/assets/images/loanImg/inner1_bg.png") no-repeat;
    background-size: 100% 100%;
    height: 550px;
    display: flex;
    justify-content: space-between;
    .left_item {
      box-sizing: border-box;
      padding: 60px 0px 0;
      border-radius: 10px;
      // width: 500px;
      h1 {
        font-size: 38px;
        color: white;
        font-weight: 700;
        // margin-bottom: 10px;
        line-height: 60px;
        span {
          color: #285dfd;
        }
      }
      h4 {
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        font-weight: normal;
        // margin: 0px 0;
      }
      & h4:nth-of-type(1) {
        margin-top: 20px;
      }
      .item_progress {
        width: 230px;
        p {
          width: 90px;
          text-align: center;
          line-height: 30px;
          border-radius: 5px;
          color: white;
          font-size: 12px;
          font-weight: bold;
          margin: 0 auto 10px;
          background: #4488eb;
        }
        img {
          width: 245px;
          height: 15px;
        }
      }
      .item_info {
        margin: 20px 0;
        display: flex;
        height: 170px;
        .info_img1 {
          width: 40px;
        }
        .info_tips {
          display: flex;
          flex-direction: column;
          p {
            flex: 1;
            display: flex;
            box-sizing: border-box;
            // margin-left: 10px;
            font-size: 12px;
            color: #666666;
            align-items: center;
            img {
              margin-right: 10px;
            }
          }
        }
        .info_img2 {
          width: 135px;
          height: 135px;
        }
      }
      .item_download {
        cursor: pointer;
        margin-top: 30px;
        color: white;
        border-radius: 5px;
        img {
          width: 160px;
          height: 50px;
          margin-right: 5px;
        }
      }
    }
    .right_item {
      img {
        height: 376px;
        margin-right: 30px;
      }
    }
  }
  .inner2 {
    position: relative;
    margin: 50px 0;
    h3 {
      font-size: 35px;
      color: black;
      text-align: center;
      margin-bottom: 40px;
      position: relative;
      // &::after{
      //   display: block;
      //   content:'';
      //   width: 80px;
      //   height: 5px;
      //   background: #4776FD;
      //   margin-top: 20px;
      // }
    }
    p {
      padding: 10px 200px 60px;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      border-bottom: 1px solid #d0d0d0;
    }
    .inner5_item {
      display: flex;
      justify-content: space-between;
      margin: auto;
      img{
        width: 100%;
      }
      .content {
        padding: 30px 10px 20px;
        color: #333333;
        width: 22%;
        border-radius: 15px;
        display: flex;
        // font-weight: 600;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 76px;
          height: 76px;
        }
        p {
          font-size: 13px;
          font-weight: bold;
          padding: 0;
          border-bottom: 0;
        }
      }
    }
  }
  .inner3 {
    position: relative;
    // background: url("~@/assets/images/loanImg/bn.png") no-repeat center bottom;
    // background-size: 1920px auto;
    padding: 50px calc((100% - 1300px) / 2) 0;
    background: #f3f3f3;
    .phone {
      position: absolute;
      top: 210px;
      left: 50%;
      transform: translate(-50%);
      width: 350px;
      img {
        width: 100%;
      }
    }
    .title {
      text-align: center;
      margin-bottom: 140px;
      p {
        color: #666666;
        font-size: 16px;
        margin: 0;
      }
      h3 {
        font-size: 24px;
        color: black;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        // &::after{
        //   display: block;
        //   content:'';
        //   width: 80px;
        //   height: 5px;
        //   background: #4776FD;
        //   margin-top: 20px;
        // }
      }
    }
    .list {
      width: 1400px;
      margin: 0 auto;
      overflow: hidden;
      // padding: 50px 0px;
      li {
        width: 650px;
        float: left;
        height: 225px;
        margin-bottom: 46px;
        img {
          width: 30px;
          height: 30px;
          margin-bottom: 20px;
        }
        .text1 {
          font-size: 22px;
          color: #333333;
          font-weight: 700;
          line-height: 45px;
        }
        .text2 {
          font-size: 14px;
          color: #666666;
          line-height: 19px;
        }
        &.left_item {
          text-align: left;
        }
        &.right_item {
          text-align: right;
        }
      }
    }
  }
  .inner4{
    background: #FAFAFA;
    .content{
      display: flex;
      .left_cell,.right_cell{
        display: flex;
      }
      h1{
        font-size: 30px;
      }
      img{
        margin:auto 150px;
        width: 420px;
        height: 310px;
      }
      .left_cell{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-right: 170px;
        p{
          font-size: 17px;
          line-height: 25px;
          color: #333;
          // padding: 0 170px 0px 0;
        }
      }
      .right_cell{
        display: flex;
        justify-content: right;
      }
    }
  }
}
@media only screen and (max-width: 720px) {
  .home_top {
    min-width: calc(100vw) !important;
    .inner {
      min-width: calc(100vw) !important;
      display: flex;
      flex-wrap: wrap;
    }
    .inner1 {
      padding: 0px calc((100% - 1200px) / 2) 0;
      height: 370px;
      position: relative;
      background-size: 100% auto;
      .left_item {
        margin: 0px 10px 10px;
        // height: 500px;
        padding: 30px 0px;
        flex: 1;
        h1 {
          font-size: 14px;
          line-height: 25px;
          // line-height: 50px;
        }
        h4 {
          color: black;
        }
        & h4:nth-of-type(1) {
          margin-top: 60px;
        }
        .item_download {
          margin-top: 30px;
          img {
            display: block;
            width: 150px;
            height: 50px;
            margin: 0 auto;
          }
        }
      }
      .right_item {
        width: calc(100vw);
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100% !important;
          position: absolute;
          bottom: 0;
          margin-right: 0;
          height: 350px;
        }
      }
    }
    .inner2 {
      min-width: calc(100vw - 40px) !important;
      width: calc(100vw - 40px) !important;
      margin: 0 auto !important;
      padding: 50px 20px;
      p {
        padding: 10px 0 20px;
      }
      h3 {
        width: 100%;
        font-size: 25px;
        text-align: center;
        margin-bottom: 20px;
      }
      .left_bg {
        width: 30px;
        height: 50px;
        top: 40px;
      }
      .inner5_item {
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        .content {
          flex: 1;
          width: 100%;
          margin: 20px 0;
        }
      }
    }
    .inner3 {
      padding: 0;
      background-size: 100% auto;
      h3 {
        width: 100%;
        margin-bottom: 30px;
      }
      .title {
        margin-bottom: 20px;
      }
      .text1 {
        text-align: center;
        line-height: 30px !important;
        margin-bottom: 10px;
      }
      .text2 {
        font-size: 12px !important;
      }
      p {
        padding: 0 20px;
        line-height: 20px;
        text-align: left !important;
        color: black !important;
      }
      .phone {
        width: 200px;
        position: relative;
        top: 0;
        margin: 30px 0;
      }
      .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        li {
          width: 100%;
          height: 100px;
          margin-bottom: 15px;
        }
      }
      .inner5_item {
        display: flex;
        flex-direction: column;
      }
    }
    .inner4{
      h4{
        padding: 0 20px;
      }
      .content{
        padding: 0 20px !important;
        display: flex;
        flex-direction: column;
        .left_cell{
          padding: 30px 0;
          margin-right: 0;
          h1{
            text-align: center;
            margin-bottom: 20px;
          }
          p{
            padding: 0 !important;
            width: 100% !important;
            margin:  15px 0;
          }
        }
        .right_cell{
          img{
            width: 100% !important;
            margin: auto;
          }
        }
      }
    }
  }
}
</style>
